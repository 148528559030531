import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Navbar from "../components/navbar-nonhome"
import { css } from '@emotion/react'
import { FaPhoneAlt, FaEnvelope, FaPen, FaMapMarkerAlt } from "react-icons/fa";
import ReactHtmlParser from 'react-html-parser'
import {
    FacebookIcon,
    LinkedinIcon,
    } from "react-share";
import Form from "../components/form-contact"
import {Trans, Link, I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';



export default function Jobs({ data }) {
    return (
        <Layout>
            <Navbar />
            <div className="headerpadding" />
          <div className="mobile"css={css`max-width: 1000px; margin: auto auto; padding-bottom: 3rem;`}>
          <div css={css`font-size: 2.8rem; line-height: 3.2rem;font-weight: 500; color: var(--text-color); padding-top: 2rem; padding-bottom: 4rem;`}>Contact</div>
            <h1><Trans>Vragen of een offerte nodig?</Trans></h1>
            <p><Trans>Vragen? Uw project met ons bespreken? Wij staan klaar om u te helpen.</Trans></p>
            <div className="contacttable mobile-col" css={css`display: flex; width: 70%`}>
                    <div className="contactitem"><FaPhoneAlt css={css`color: var(--accent-color); margin-right: 10px; overflow: hidden;`}/><a href={`tel:${data.wp.websiteSettings.algemeneInfo.telefoonnummer}`}>{data.wp.websiteSettings.algemeneInfo.telefoonnummer}</a></div>
                    <div className="contactitem"><FaEnvelope css={css`color: var(--accent-color); margin-right: 10px;`} /><a href={`mailto:${data.wp.websiteSettings.algemeneInfo.mailadres}`}>{data.wp.websiteSettings.algemeneInfo.mailadres}</a></div>
                    <div className="contactitem"><FaPen css={css`color: var(--accent-color); margin-right: 10px;`} /><Link to="#contactform"><Trans>Contactformulier</Trans></Link></div>
                </div>
                <h1 id="info">Info</h1>
                <div css={css`display: flex; width: 50%`}>
                    <div css={css`width: 25px`}><FaMapMarkerAlt css={css`color: var(--accent-color); margin-right: 10px;`}/></div>
                    <div>{ReactHtmlParser(data.wp.websiteSettings.algemeneInfo.adres)}<br/>
                    {ReactHtmlParser(data.wp.websiteSettings.algemeneInfo.btwNummer)}<br/>
                    <div css={css`display:flex; align-items: center; font-size: 1rem; margin-top: 2rem; padding-bottom: 4rem`}><div css={css`margin-right: 0.5rem;`}><Trans>Asbitech volgen:</Trans></div><span><a href="https://www.facebook.com/Asbitech-bv-516038008526255" target="_blank" rel="noopener nofollow"><FacebookIcon className="sharebutton" css={css`margin-right: 0.2rem;`} size={24} round={false}></FacebookIcon></a><a href="https://www.linkedin.com/company/asbitech-bvba/" target="_blank" rel="noopener nofollow"><LinkedinIcon className="sharebutton" size={24} round={false}></LinkedinIcon></a></span> </div><br/>

                    </div>
                </div>
                <h1 id="contactform" css={css`margin-bottom: 1rem`}><Trans>Contactformulier</Trans></h1>
                <Form />
              </div>
        </Layout>
    )}

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
      }
    wp {
      websiteSettings {
        algemeneInfo {
          adres
          btwNummer
          mailadres
          telefoonnummer
        }
      }
    }
  } 
`